import React from 'react'

function IndexComp() {
  return (
    <div className='homepageArea'>
        <h1>WELCOME TO EUMAPS</h1>
        <p>Platform of Useful Applications and Calculators</p>
    </div> 
  )
}

export default IndexComp